<template>
    <div class="index">
        <!-- 新闻详情 -->
        <div :class="isPc === 'true' ? 'main' : 'phoneStyle main'">
            <div class="left">
                <div class="contain">
                    <div class="title">
                        <img src="../../assets/images/index/gaugeLogo.png" alt="">
                        <!-- <p class="p2">
                            <span v-show="newsInfo.withHot">热门</span>
                            {{ newsInfo.title }}
                        </p> -->
                    </div>

                    <div class="text">
                        <p class="p2">
                            <span v-show="newsInfo.withHot">热门</span>
                            {{ newsInfo.title }}
                        </p>
                        <p class="time p3">— {{ newsInfo.created }}</p>
                        <!-- <p class="p3" v-show="newsInfo.summary">
                            {{ newsInfo.summary }}
                        </p>-->

                        <!-- <img v-show="newsInfo.showImage" :src="`${$store.state.imgUrl}${newsInfo.showImage}`" alt=""> -->
                        <p class="p3" v-html="newsInfo.content">

                        </p>
                        <!-- <p class="p3">
                        <figure class="image">
                            <img src="https://www.dasmindfit.com/attachment/20230322/1b33714dc5914540a24a5c34c4b15591.jpg">
                        </figure>
                        <figure class="image">
                            <img src="https://www.dasmindfit.com/attachment/20230322/93cff6c2952745609f17623f53d33ed7.jpg">
                        </figure>
                        </p> -->


                    </div>
                </div>
                <div class="btn">
                    <span class="span1" @click="back">返回列表</span>
                </div>
            </div>
            <div class="right">
                <div class="hot">
                    <ul>
                        <li>热门新闻</li>
                        <li v-for="item in hotList.slice(0, 5)" :key="item.id" @click="checkNews(item.id)">{{ item.title }}
                        </li>
                        <!-- <li>热门新闻标题1热门新闻标题1</li>
                        <li>热门新闻标题1热门新闻标题1</li>
                        <li>热门新闻标题1热门新闻标题1</li>
                        <li>热门新闻标题1热门新闻标题1</li> -->
                    </ul>
                </div>
                <div class="new">
                    <ul>
                        <li>最新新闻</li>
                        <!-- <li v-for="item in newsList" :key="item.id">{{ item.title }}</li> -->
                        <li v-for="item in newList" @click="checkNews(item.id)" :key="item.id">{{ item.title }}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { newsDetail, newsListAPI } from '@/api/news'
export default {
    data() {
        return {
            isPc: true,
            newsInfo: {},
            newsId: 1,
            newList: [], //最新新闻
            hotList: [] //热门新闻
        }
    },
    methods: {
        // 返回新闻列表
        back() {
            this.$router.push('/news')
        },
        // 获取新闻详情
        async getDetail() {
            const res = await newsDetail({ id: this.newsId });
            console.log(res);
            if (res.state === 'ok' && res.detail) {
                this.newsInfo = res.detail;
                let str = res.detail.content;
                // this.replaceImgPath(this.newsInfo.content)
                // console.log(this.newsInfo.content)
                // console.log(str.slice(0,str.indexOf('/attachment')))
                // this.newsInfo.content = str.slice(0, str.indexOf('/attachment')) + 'https://webadmin.dasmindfit.com' + str.slice(str.indexOf('/attachment'), str.length - 1);
                // console.log(this.newsInfo.content)
                // console.log(str.slice(694,str.length-1))
            }
        },
        // 重复提取当前内容里面的
        replaceImgPath(val) {
            console.log(val)
            // let str = val;
            // console.log(str.slice(0,str.indexOf('/attachment')))
            // if ( val.indexOf('img src="/attachment') !== -1) {
            //     console.log('1111' + val.lastIndexOf('https://webadmin.dasmindfit.com') !== -1)
            //     if (val.lastIndexOf('https://webadmin.dasmindfit.com') !== -1 ) {
            //         console.log(2222)
            //         let str = this.newsInfo.content;
            //         this.newsInfo.content = str.slice(0, str.indexOf('/attachment')) + 'https://webadmin.dasmindfit.com' + str.slice(str.indexOf('/attachment'), str.length - 1);
            //         this.replaceImgPath(this.newsInfo.content)
            //         // console.log(this.newsInfo.content)
            //     } else {

            //     }

            // }

        },
        // 获取最新新闻
        async getNewList() {
            // let time = Date.now();
            let info = {
                categoryId: Number(this.$store.state.newsTypeId),
                pageNumber: 1,
                pageSize: 5,
                // withHot: 'true'
                // orderBy:' desc'
            }
            const res = await newsListAPI(info);
            console.log(res);
            if (res.state === 'ok' && res.page) {
                // console.log(res.page)
                this.newList = res.page.list
            }
        },
        // 获取热门新闻
        async getHotList() {
            // let time = Date.now();
            let info = {
                categoryId: Number(this.$store.state.newsTypeId),
                pageNumber: 1,
                pageSize: 1000,
                // orderBy:'with_hot desc'
            }
            const res = await newsListAPI(info);
            console.log(res);
            if (res.state === 'ok' && res.page) {
                // console.log(res.page)
                let arr = res.page.list;
                this.hotList = [];
                if (arr.length > 0) {
                    arr.forEach(item => {
                        if (item.withHot === true) {
                            // console.log(item)
                            this.hotList.push(item);
                        }
                    })
                }
            }
            // console.log(this.hotList)
        },
        // 切换新闻详情
        checkNews(val) {
            this.newsId = val;
            this.getDetail();
        }
    },
    created() {
        this.isPc = sessionStorage.getItem('isPc')
        this.newsId = Number(this.$route.query.id);
        this.getDetail();
        this.getHotList();
        this.getNewList();
    }
}
</script>

<style lang="scss" scoped>
.index {
    padding-top: 40px;

    ::v-deep .image.image_resized.image-style-block-align-left {
        width: 90% !important;

        img {
            width: 100%;
        }
    }

    .main {
        flex-wrap: wrap;
        justify-content: space-between;

        .left {
            width: 68%;


            .contain {
                border: 1px solid #DDDDDD;

                .title {
                    width: 100%;
                    // height: 48px;
                    background: var(--listStyle-color);
                    padding: 8px 20px;
                    box-sizing: border-box;
                    // display: flex;
                    text-align: right;
                    // p {
                    //     // display: inline-block;
                    //     height: 100%;
                    //     line-height: 32px;
                    //     font-weight: 600;
                    //     color: var(--titleFont-color);

                    //     span {
                    //         width: 28px;
                    //         height: 17px;
                    //         background: #D8D8D8;
                    //         border-radius: 2px;
                    //         font-size: 10px;
                    //         font-weight: 500;
                    //         color: #4B4B4B;
                    //         line-height: 17px;
                    //         text-align: center;
                    //         display: inline-block;
                    //     }
                    // }

                    img {
                        margin-right: 30px;
                        height: 32px;
                    }
                }

                .text {
                    padding: 20px 30px;
                    min-height: 500px;

                    .p2 {
                        // display: inline-block;
                        text-align: center;
                        height: 100%;
                        line-height: 32px;
                        font-weight: 600;
                        color: var(--titleFont-color);
                        margin-bottom: 20px;

                        span {
                            width: 28px;
                            height: 17px;
                            background: #D8D8D8;
                            border-radius: 2px;
                            font-size: 10px;
                            font-weight: 500;
                            color: #4B4B4B;
                            line-height: 17px;
                            text-align: center;
                            display: inline-block;
                        }

                        // img{
                        //     width: 100%;
                        // }
                    }

                    .p3 {
                        font-weight: 500;
                        color: var(--memoFont-color);

                        ::v-deep img {
                            width: 100% !important;
                        }

                        ::v-deep span {
                            img {
                                width: 100% !important;
                            }
                        }
                    }

                    .time {
                        text-align: right;
                        margin-bottom: 10px;
                        color: var(--newsTitle-color);

                    }

                    img {
                        width: 100%;
                        margin-top: 15px;
                        margin-bottom: 20px;
                    }
                }
            }

            .btn {
                text-align: center;
                margin: 20px 0px;

                span {
                    line-height: 40px;
                    display: inline-block;
                    width: 124px;
                    height: 40px;
                    border: 1px solid var(--custom-color);
                    font-weight: 600;
                    color: var(--headFont-color);
                    cursor: pointer;
                }
            }

            // background: #000;
        }

        .right {
            width: 30%;

            ul {
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    font-size: 15px;
                    height: 48px;
                    line-height: 48px;
                    border: 1px solid #EEEEEE;
                    border-top: none;
                    padding: 0 20px;
                    color: var(--memoFont-color);
                    cursor: pointer;
                    text-overflow: -o-ellipsis-lastline;
                    overflow: hidden; //溢出内容隐藏
                    text-overflow: ellipsis; //文本溢出部分用省略号表示
                    display: -webkit-box; //特别显示模式
                    -webkit-line-clamp: 1; //行数
                    line-clamp: 1;
                    -webkit-box-orient: vertical;

                    &:first-child {
                        border: none;
                        cursor: auto;
                        background: #EEEEEE;
                        font-weight: 600;
                        color: var(--headFont-color);

                    }
                }
            }

            .new {
                margin-top: 30px;
            }

        }
    }
}

@media screen and (max-width: 600px) {
    .index {

        .main {
            .left {
                width: 704px;
            }

            .right {
                width: 350px;
            }
        }
    }
}

// .left {
//             width: 704px;
</style>